import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';

import Slide from '@material-ui/core/Slide';
import { Button, makeStyles } from '@material-ui/core';

function HideOnScroll(props : any ) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const useStyles = makeStyles({
   title: {
       flexGrow: 1,
       textAlign: 'left',
       color: 'white',
       textDecoration: 'none',
       fontFamily: 'Niconne',
       fontSize: '3em'
   },
   baseBar: {
        background: 'linear-gradient(45deg, #000000 30%, #191919 90%)',
   },
   connectButton: {
       borderColor: '#5D3BF4',
       float: 'right'
   }
   
  });

  


export default function HideAppBar(props : any) {
    const classes = useStyles();
    const handleConnection = () => {
        if (props.address === null) {
            // connect
            if (props.onConnect) {
                props.onConnect();
            }
        } else { 
            // disconnect 
            if(props.onDisconnect) {
                props.onDisconnect();
            }
        }
    }
    return (
        <React.Fragment>
        <CssBaseline />
        <HideOnScroll {...props}>
            <AppBar className={classes.baseBar}>
                <Toolbar>
                    <a href="https://knightsofdegen.io" className={classes.title}>
                        <img src={`${process.env.PUBLIC_URL}/images/kod-wordmark-white.png`} height={75} width={'auto'} style={{paddingTop: 10, paddingBottom: 10, marginRight: 10}} />
                    </a>
                    <Button onClick={handleConnection} color="inherit" variant="outlined">{props.address ? `${props.address.substring(0,10)}... Disconnect` : 'Connect Wallet'}</Button>
                </Toolbar>
            </AppBar>
        </HideOnScroll>
        <Toolbar />
        </React.Fragment>
    );
}
