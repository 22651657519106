import { useEffect, useState } from 'react';
import './App.css';


import Web3 from 'web3';
import { Box, Button, Container, createStyles, createTheme, Grid, LinearProgress, makeStyles, MenuItem, Paper, Select, Theme, ThemeProvider, Typography, withStyles } from '@material-ui/core';
import detectEthereumProvider from '@metamask/detect-provider';
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";
import { WalletLink } from 'walletlink';
import Fortmatic from "fortmatic";
import Torus from "@toruslabs/torus-embed";
import Portis from '@portis/web3';
import HideAppBar from './components/scrollingAppBar';
import {BorderLinearProgress} from './components/borderLinearProgress';

var abi = require('./abi.json');
const presaleAbi = require('./abis/presaleAbi.json');
const knightsAbi = require('./abis/knightsAbi.json');

const formaticKey = 'pk_live_22A7C19EDFD9C88B';
const portisKey = '6282a442-bc2f-4a7f-8016-be12f56e2885';
const knightCost = '0.088';

const useStyles = makeStyles({
  root: {
    background: 'linear-gradient(45deg, #000000 30%, #191919 90%)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white',
    padding: 20,
    minHeight: '100vh',
    backgroundImage: `url("${process.env.PUBLIC_URL}/images/KoD-Castle-Alternate.jpg")`
  },
  brands: {
    height: '200px',
    margin: 10,
    boxShadow: '0 0 3px 3px #5237F6'
  },
  connectionInfo: {
    background: 'rgba(0,0,0,0.3)',
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px #000',
    color: 'white',
    padding: 20,
    textAlign: 'left',
    marginBottom: 20,
    marginTop: 20
  },
  knightImage: {
    width: '80%',
    margin: '0 auto',
    boxShadow: '0 0 6px 1px #222'
  },
  characterImages: {
    boxShadow: '0 0 3px 3px #5237F6'
  },
  dropPromoInfo: {
    display: 'inline-block',
    margin: 10,
    border: 'solid 2px black',
    background: 'linear-gradient(45deg, #000000 30%, #191919 90%)',
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 15
  },
  f2: {
    fontFamily: 'Lora'
  }
});


const theme = createTheme({
  typography: {
    fontFamily: [
      'Chivo',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontWeight: 900,
      fontFamily: 'Niconne'
    },
    h6: {
      fontWeight: 900
    },
  }, 
});

interface Knight {
  id: string;
  meta: any;
  image?: string;
}

function App() {
  const classes = useStyles();
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [walletError, setWalletError] = useState<String| null>(null);
  const [accountAddress, setAccountAddress] = useState<string | null>(null);
  const [mintAmount, setMintAmount] = useState<number>(2);
  const [mintTransactionAddress, setMintTransactionAddress] = useState<string|null>(null);
  const [knights, setKnights] = useState<Knight[]>([]);
  const [findingKnights, setFindingKnights] = useState<boolean>(false);
  const [saleStarted, setSaleStarted] = useState<boolean>(false);
  const [myTotalKnights, setMyTotalKnights] = useState<number>(0);
  const [isMinting, setIsMinting] = useState<boolean>(false);
  
  const contractDetails = {
    'localhost': {
      contracts: {
        presaleContractAddress: '0x610178dA211FEF7D417bC0e6FeD39F05609AD788',
        knightsContractAddress: '0xB7f8BC63BbcaD18155201308C8f3540b07f84F5e'
      },
      httpProviderEndpoint: 'http://localhost:8545',
    },
    'ropsten': {
      contracts: {
        presaleContractAddress: '0x8bC10d2F2d89349795C8Ac0F18FC9C7D258b2C06',
        knightsContractAddress: '0x2C9e7CdB479b25E65f3dA2E6545f8f5f002B8A0F'
      },
      httpProviderEndpoint: 'https://eth-ropsten.alchemyapi.io/v2/BAqxzGlo3oY9yD-rPiXz8eqlovYYmZ_4',
    },
    'mainnet': {
      contracts: {
        presaleContractAddress: '0xef5afc5fb5ea26ac16e00c616d49be1fed849ed0',
        knightsContractAddress: '0xE3f92992BB4F0f0D173623A52b2922d65172601d'
      },
      httpProviderEndpoint: 'https://eth-mainnet.alchemyapi.io/v2/BAqxzGlo3oY9yD-rPiXz8eqlovYYmZ_4',
    }
  };

  const chosenNetwork = 'mainnet';

  const [wProvider, setWProvider] = useState<any>(null);
  const [w3Modal, setW3Modal] = useState<any>(null);
 
  useEffect( () => {
    setInterval(async () => {
      await checkMintingDetails();
    }, 15000);
    
    checkMintingDetails();
    //getGasData();
    setUpWeb3Modal();
  }, []);

  const defaultProviderOptions = {
    walletconnect: {
      display: {
        name: "Mobile"
      },
      package: WalletConnectProvider,
      options: {
        infuraId: "ff4b509e32574ea2adc7a1fffc870bd9" // required
      }
    },
    'custom-coinbase': {
      display: {
        logo: '/coinbase.svg', 
        name: 'Coinbase Wallet',
        description: 'Scan with WalletLink to connect',
      },
      options: {
        appName: 'Knights of Degen', // Your app name
        networkUrl: `https://mainnet.infura.io/v3/ff4b509e32574ea2adc7a1fffc870bd9`,
        chainId: 1,
      },
      package: WalletLink,
      connector: async (_ : any, options : any) => {
        const { appName, networkUrl, chainId } = options
        const walletLink = new WalletLink({
          appName
        });
        const provider = walletLink.makeWeb3Provider(networkUrl, chainId);
        await provider.enable();
        return provider;
      },
    },
    fortmatic: {
      package: Fortmatic, // required
      options: {
        key: formaticKey // required
      }
    },/*
    torus: {
      package: Torus,
    },
    portis: {
      package: Portis, // required
      options: {
        id: portisKey // required
      }
    }*/
  };

  const setUpWeb3Modal = async () : Promise<any> => {
    const web3Modal = new Web3Modal({
      network: chosenNetwork, // optional
      cacheProvider: true, // optional
      disableInjectedProvider: false,
      providerOptions: defaultProviderOptions // required
    });
    setW3Modal(web3Modal);
    return web3Modal;
  };

  const connectWallet = async () => {
    try {
      let w3 = w3Modal
      if (!w3) {
        w3 = await setUpWeb3Modal();
      }

      let provider = await establishProvider();
      const accounts = await provider.request({method: 'eth_accounts'});
      if (accounts.length === 0) {
        setWalletError(`Error: you have no accounts connected`);
      } else {
        setIsConnected(true);
        setAccountAddress(accounts[0]);
      }

      
        findKnights();      
    } catch (error) {
      alert('Could not connect wallet');
    }

  };

  const disconnectWallet = async () => {
    await w3Modal.clearCachedProvider();
    setWProvider(null);
    setAccountAddress(null);
    setIsConnected(false);
    setMintAmount(2);
  };

  const establishProvider = async () : Promise<any> => {
    try{
      if (wProvider) {
        return wProvider;
      }
      const providerOptions = {
        
        walletconnect: {
          display: {
            name: "Mobile"
          },
          package: WalletConnectProvider,
          options: {
            infuraId: "ff4b509e32574ea2adc7a1fffc870bd9" // required
          }
        }
      };
      
      const provider = await w3Modal.connect();
      provider.on("accountsChanged", (accounts : string[]) => {
        if(accounts.length >= 1) {
          setAccountAddress(accounts[0]);
          //findKnights();
        }
      });
      setWProvider(provider);
      
      const web3 = new Web3(provider);
      return provider;
    } catch (error) {
      return null;
    }
  };

  const mint = async (isPresale : boolean) => {
    try{ 
      setIsMinting(true);
      const web3Modal = new Web3Modal({
        network: chosenNetwork, // optional
        cacheProvider: true, // optional
        disableInjectedProvider: false,
        providerOptions: defaultProviderOptions // required
      });
      
      const provider = await web3Modal.connect();
      setWProvider(provider);
      
      const web3 = new Web3(provider);
      console.log(`Got Web3`, web3);

      //let gd = await getGasData();

      const accounts = await provider.request({method: 'eth_accounts'});
      if (accounts.length === 0) {
        setWalletError(`Error: you have no accounts connected`);
      } else {
        setIsConnected(true);
        setAccountAddress(accounts[0]);
      }

      let amount = mintAmount;
      let abiItem = knightsAbi['abi'];
      const paymentAmount = web3.utils.toHex(amount * parseInt(web3.utils.toWei(knightCost, 'ether')));
      let contract = new web3.eth.Contract(abiItem, contractDetails[chosenNetwork].contracts.knightsContractAddress);
      // @ts-ignore
      let callData = contract.methods.mint(amount).encodeABI();

      let gasLimitEstimation = Math.floor(195000 * mintAmount);
  
      const mintParams = [
        {
          from: accounts[0],
          to: contractDetails[chosenNetwork].contracts.knightsContractAddress.trim().toLowerCase(),
          gas: web3.utils.toHex(gasLimitEstimation),
          value: paymentAmount, 
          data: callData,
        },
      ];
      console.log(mintParams);
  
      
        // @ts-ignore
        let res = await provider.request({
          method: 'eth_sendTransaction',
          params: mintParams,
        })
        setMintTransactionAddress(res);
    } catch (error :any) {
      setIsMinting(false);
      alert(`Minting Error. Read error and then please contact support in Discord or try again: \n\n${error['message']}`)
    }
    setIsMinting(false);
  }

  const sleep = (ms : any) =>
    new Promise(resolve => setTimeout(resolve, ms));


  const findKnights = async () => {
    
    setFindingKnights(true);
    
    try {
      let provider = await establishProvider();
     
      if (provider == null) {
        console.log(provider)
        setFindingKnights(false);
        return;
      };
      const accounts = await provider.request({method: 'eth_accounts'});
      setAccountAddress(accounts[0])
      setIsConnected(true);
      let abiItem = knightsAbi['abi'];
      let w3 = new Web3(new Web3.providers.HttpProvider(contractDetails[chosenNetwork].httpProviderEndpoint));

      if(w3 == null) {
        console.log(w3)
        setFindingKnights(false);
        return;
      }
      let contract = new w3.eth.Contract(abiItem, contractDetails[chosenNetwork].contracts.knightsContractAddress);
      let myKnights = await contract.methods.walletOfOwner(accounts[0]).call();
      setMyTotalKnights(myKnights.length);
      console.log(myKnights);
      
      let ms : Knight[] = [];
      for(let i = 0; i < Math.min(20,myKnights.length); i += 1) {
        console.log("Searching...", i)
        let id = myKnights[i];
        
        
        let uri = await contract.methods.tokenURI(id).call();
        console.log('URI: ' + uri);
        uri = uri.split('ipfs://')[1];



        try {
          // Resolve with Cloudflare IPFS
          let metadata = await fetch(`https://cloudflare-ipfs.com/ipfs/${uri}`);
          const jsonData = await metadata.json();
          let metaMap : any = {};
          (jsonData['attributes'] || []).forEach( (attr : any) => {
            metaMap[attr['trait_type']] = attr['value'];
          })
          ms.push({
            id: id,
            meta: metaMap,
            image: `https://gateway.pinata.cloud/ipfs/${(jsonData.image || "").split('ipfs://')[1]}`,
          })
          
          setKnights(ms);
        } catch (error) {
          console.log(`Error fetching Knight ${id} data.`)
        }

        // Sleep so we don't crush our rate limit
        await sleep(250);        
      }
      
      setKnights(ms);
      
      setFindingKnights(false);
    } catch (error) {
      console.log(`Could not find knights.`, error);
      setFindingKnights(false);
    }
    setFindingKnights(false);

  }

  const checkMintingDetails = async () : Promise<boolean> => {
    return new Promise<boolean>( async (resolve, reject) => {
        try {
          let abiItem = knightsAbi['abi'];
          // @ts-ignore
          let w3 = new Web3(new Web3.providers.HttpProvider(contractDetails[chosenNetwork].httpProviderEndpoint));
          let contract = new w3.eth.Contract(abiItem, contractDetails[chosenNetwork].contracts.knightsContractAddress);
          let so = await contract.methods.isSaleOpen().call();
          setSaleStarted(so);

          resolve(true);
        }
        catch (error) {
          console.log(`Fetching minting details is failing: ${error}`);
          reject(error);
        }
    })
    
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Box className={classes.root}>
          <HideAppBar 
            address={accountAddress}
            onConnect={connectWallet}
            onDisconnect={disconnectWallet}
          />
          <Box style={{backgroundColor: 'red', color: 'white'}}>
            <Typography variant="h1">{walletError}</Typography>
          </Box>
          <Container style={{marginTop: 50}}>
            <Typography variant="h5" component="h2" className={classes.f2}>Welcome Squire! Are you ready to become a Knight?</Typography>
          </Container>
          <Container>
            <Typography variant='h4' className={classes.dropPromoInfo}>Mint Knight NFTs</Typography>
            <Typography variant='h4' className={classes.dropPromoInfo}>{knightCost}Ξ</Typography>
            <Typography variant='h4' className={classes.dropPromoInfo}>Up to 2 Knights / Transaction</Typography>
          </Container>
          <Container style={{marginTop: 20}}>
            
           
            <Typography variant="h6" component="p" className={classes.f2}>
              It's time for you to join the Knights of Degen! The public drop is here and approximately 2,500 Knights remain to be claimed. 
              Will you be able to acquire yours at the chaos in the castle, or will you be thwarted in battle. Find out and join us! 
              Each Knight costs {knightCost}Ξ and you may purchase up to 2 Knights per transaction.
            </Typography>
          </Container>
          <Container>
            <Box style={{textAlign: 'left'}}>
              <Typography variant="h6" component="p">Instructions</Typography>
              <Typography variant="body1">1. Connect your wallet.</Typography>
              <Typography variant="body1">2. Choose # of Knights to mint.</Typography>
              <Typography variant="body1">3. Click Purchase &amp; Mint.</Typography>
              <Typography variant="body1">4. Revel in your degen glory back on Discord with your fellow Knights!</Typography>
            </Box>
          </Container>
          
          
          <Container className={classes.connectionInfo}>
            <Grid container>
              <Grid item xs={12} sm={7}>
                <Box>
                  {!isConnected && (
                    <Button variant="contained" onClick={connectWallet} >Connect Wallet</Button>
                  )}

                  {isConnected && (
                    <>
                      <Typography variant="caption">Connected: {accountAddress}</Typography>
                      <Button onClick={disconnectWallet} variant="outlined">Disconnect Wallet</Button>

                    </>
                  )}
                  
                  <br/>
                    <br/>
                </Box>
              
              
                <Box>
                  {isConnected && (
                  <Box>
                    {myTotalKnights != null && myTotalKnights > 0 && (
                      <Typography>Your connected wallet currently owns {myTotalKnights} total Knights!</Typography>  
                    )}
                    
                      <Typography>Minting ({knightCost}Ξ per Knight):</Typography>
                        <Select
                          value={mintAmount}
                          onChange={(e :any) => {
                            setMintAmount(e.target.value);
                          }}
                          style={{background: 'white', color: 'black', marginRight: 10}}
                        >
                            { Array.from(new Array(2).keys()).map( (_, i) => (
                              <MenuItem value={i+1} selected={i === 2}>{i+1}</MenuItem>
                            ))}
                        </Select>
                      
                      <Box>
                        <Button variant="contained" onClick={() => mint(true)} disabled={!saleStarted || isMinting || !isConnected } >Purchase &amp; Mint</Button>  
                        {isMinting && <Typography>You are currently minting.</Typography>}
                        <Box>
                          <Typography variant="caption">You may mint up to 2 Knights per transaction at {knightCost}Ξ per Knight NFT.</Typography>
                        </Box>
                      </Box>
                  </Box>
                  )}
                   
                  </Box>
                </Grid>
                <Grid item xs={12} sm={5}>
                  <Container>
                    <Typography variant="h4">{saleStarted ? `Chaos in the Castle has begun! Mint your Knights!` : 'The Chaos in the Castle, aka public drop, has not yet started.'}</Typography>
                    
                    <Typography><a target="_blank" href="https://etherscan.io/address/0xe3f92992bb4f0f0d173623a52b2922d65172601d" style={{textDecoration: 'underline', color: 'white', fontWeight: 'bold'}}> Knights Verified Contract </a></Typography>
                  </Container>
                </Grid>
              </Grid>
              <br/>
              <Typography variant="body2" component="p">
                Note: If you are having issue connecting wallet with Coinbase Wallet or Metamask, please try using their mobile in app browser instead. 
                If you are having issues with Ledger + MM, please try enabling debug mode in your Ledger or ensuring you are running the latest versions of each. Thanks!
              </Typography>
          </Container>
          {mintTransactionAddress && (
            <Container className={classes.connectionInfo}>
              <Typography>Last mint transaction id: {mintTransactionAddress}. <br/>View on <a target="_blank" href={`https://etherscan.io/tx/${mintTransactionAddress}`} style={{color: 'white'}}>etherscan</a>.</Typography>
            </Container>
          )}

            

          <Container style={{textAlign: 'left'}} className={classes.connectionInfo}>
            <Box style={{textAlign: 'left'}}>
              <Typography variant="h5">Knighthood Has It’s Privileges…</Typography>
              
              <Typography variant="h6">Betting Alpha and Insider AMAs: </Typography>
              <Typography variant="body1">The KoD Discord will provide daily sports betting and NFT alpha from the King’s Court (sports and NFT insiders), with weekly AMA participation alongside major sports and cultural happenings. The Discord is currently open to all, but will be token restricted post-drop. </Typography>
              <br/>
              <Typography variant="h6">Vault and Tokens: </Typography>
              <Typography variant="body1">The Roundtable (team) behind KoD will be establishing the “King’s Purse” (a vault) to the benefit of knight holders. The “King’s Purse” will be a vault established to benefit all loyal knight holders. The KoD community will have a voice in the acquisition of high-profile sports NFTs, funded initially with 5% of mint sales, and then an ongoing 25% of royalties. The value of items in the "King’s Purse" will be distributed via tokens and/or fractions to knight holders.</Typography>
              <br/>
              <Typography variant="h6">Exclusive Pools and Contests:  </Typography>
              <Typography variant="body1">Knights will have access to free entry pools with $/ETH prizes throughout every sports season, including pools for NFL Survivor, March Madness, The Masters, NBA Playoffs, as well as weekly competitions and more. You can learn more about our first survivor pool, open to all early Discord members, here.</Typography>
              <br/>
              <Typography variant="h6">Sports Bar in the Metaverse: </Typography>
              <Typography variant="body1">The Roundtable has entered a discovery process around purchasing a parcel of land for the Degen Tavern in the Decentraland. Metaverse. All knight holders would partial owners with access.  </Typography>
            </Box>
            <Box style={{textAlign: 'center'}}>
              <img src={`${process.env.PUBLIC_URL}/images/kod-mug-logo.png`}  width={'15%'} height={'auto'} style={{paddingTop: 10, paddingBottom: 10, marginRight: 10}} />
              <Typography variant="h6">Powered by: Ethereum, Degens</Typography>
            </Box>

            
          </Container>

          
          <Container>
            <Typography variant="h6" style={{textAlign: 'left'}}>My Knights:</Typography>

            <Container className={classes.connectionInfo}>
              <Typography variant="body1">
                After minting, if you'd like to check out your Knight NFTs, use the button below. Note: there is sometimes a delay between minting and it showing up. Always check your account and transaction on etherscan if you are unsure of what is in your wallet or not. Sometimes the processing can take a while depending on the gas price you set.
              </Typography>
              <br/>
              <Typography>Art reveal will be the first week of October, following the public drop.</Typography>
              <Button variant="contained" onClick={findKnights} disabled={findingKnights}>{findingKnights ? '...searching for Knights...' : 'Find my Knights'}</Button>
              {knights.length ==0 && (
                <>
                  <br/>
                  <Typography variant="caption">if all your knights aren't showing, click 'find my knights'</Typography>
                </>
              )}
              <p>You have {myTotalKnights} knights found. {findingKnights ? 'Searching for their data.' : ''}</p>
              { myTotalKnights > 20 && (
                <p>Only your first 20 Knights are shown below.</p>
              )}
              {knights.length > 0 && (
                <Box>
                  <hr/>
                  <Grid container spacing={3}>
                    {knights.map( (knight : Knight, i) => (
                      <Grid key={`m-${i}`} item xs={12} sm={6}>
                        <Paper className={classes.connectionInfo}>
                          <Typography>#{knight.id}</Typography>
                          <img className={classes.knightImage} src={knight.image} />
                          <Box>
                            <hr/>
                            <Typography variant="caption">Attributes to be revealed after public drop.</Typography> <br/>
                            <Typography variant="caption">Image above is for pre-reveal purposes only and does not reflect your final Knight art nor traits.</Typography>
                            {/*
                            <Typography variant="body1" key={"armor"}>Game: <b>{knight.meta['game']}</b></Typography>
                            <Typography variant="body1" key={"armor"}>Date: <b>{knight.meta['date']}</b></Typography>
                            <Typography variant="body1" key={"armor"}>Time: <b>{knight.meta['time']}</b></Typography>
                             */}
                          </Box>
                        </Paper>
                      </Grid>
                    ))}
                    
                  </Grid>
                </Box>
              )}

            </Container>
          </Container>



          <Box>
            <Typography>Back to <a href="https://knightsofdegen.io" style={{textDecoration: 'none', color: '#FFF', fontWeight: 'bold'}}> KnightsOfDegen.io</a></Typography>
          </Box>

        </Box>
      </div>
    </ThemeProvider>
  );
}

export default App;
